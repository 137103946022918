// const API_URL = `${window.location.origin}/api/v1`;
// const API_URL = `http://192.168.203.128:5000/api/v1`;
const API_URL = `https://lkqxuxupb44ahz6mbx6eu7sc7q0knvho.lambda-url.ap-southeast-1.on.aws/api/v1`;
const PLAYGROUND_API_URL = `https://age-estimation-playground.parallelchain-lab.io/api/v1`;

const getConfig = { method: 'GET' };
const postConfigWithForm = (formData) => {
  return { method: 'POST', body: formData };
};
const postConfigWithJSON = (jsonData) => {
  return {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(jsonData),
  };
};

export const getUserId = () => {
  return parseInt(localStorage.getItem('id'));
};
export const getUserPhrase = () => {
  return localStorage.getItem('phrase');
};
export const setUserId = (token) => localStorage.setItem('id', token);
export const setUserPhrase = (phrase) => localStorage.setItem('phrase', phrase);
export const clearLocalStorage = () => {
  localStorage.removeItem('id');
  localStorage.removeItem('phrase');
};

// *
// Commands / Actions
// HOC

// URL Configs
const urlConfig = {
  // Default Module (on '/') that complies to IMDA, does not store audio recording in db
  imda: {
    getRandomPhrase: `${API_URL}/random_phrase`,
    sendAudioRecording: `${API_URL}/s2t`,
    getAgeGroupResult: `${API_URL}/s2a`,
    sendQuestionnaire: `${API_URL}/question`,
    sendFeedback: `${API_URL}/feedback`,
  },
  // Playground module ('/playground'), stores audio recording in db
  playground: {
    getRandomPhrase: `${PLAYGROUND_API_URL}/random_phrase`,
    regenerateRandomPhrase: `${PLAYGROUND_API_URL}/regenerate`,
    sendAudioRecording: `${PLAYGROUND_API_URL}/s2t`,
    getAgeGroupResult: `${PLAYGROUND_API_URL}/s2a`,
    sendQuestionnaire: `${PLAYGROUND_API_URL}/question`,
    sendFeedback: `${PLAYGROUND_API_URL}/feedback`,
  },
};

// Fetch
const sendRequest = async (url, config) => {
  try {
    const res = await fetch(url, config);
    const json = await res.json();
    console.log('result json', json, res);
    return json;
  } catch (e) {
    console.log('error', e);
  }
};

// MODULE CHOICES: 'imda' / 'playground'
export function API(module) {
  // Generate random phrase and id on FIRST mount
  const getRandomPhrase = async () => {
    const result = await sendRequest(
      urlConfig[module].getRandomPhrase,
      getConfig
    );
    if (module === 'playground') setUserId(result.id); // set user ID
    return result; // { id: .., phrase: string }
  };

  // Regenerate Random Phrase to keep count of random phrase generations
  const regenerateRandomPhrase = async () => {
    const formData = new FormData();
    formData.append('id', getUserId());

    const result = await sendRequest(
      urlConfig[module].regenerateRandomPhrase,
      postConfigWithForm(formData)
    );

    if (module === 'playground') setUserId(result.id); // set user ID

    return result; // { id: .., phrase: string }
  };

  // Send Audio Recording to AI Model, to check whether the audio MATCHES with phrase
  const sendAudioRecording = async (audio) => {
    const formData = new FormData();

    if (module === 'imda') {
      formData.append('audio', audio);
      formData.append('phrase', getUserPhrase());
    }

    if (module === 'playground') {
      formData.append('audio', audio);
      formData.append('id', getUserId());
    }

    const result = await sendRequest(
      urlConfig[module].sendAudioRecording,
      postConfigWithForm(formData)
    );
    return result; // { id: .., match: boolean, transcript: string }
  };

  // Send Audio Recording to AI Model to get Age Group Result
  const getAgeGroupResult = async (audio) => {
    const formData = new FormData();
    if (module === 'imda') {
      formData.append('audio', audio);
    }

    let jsonData = {};
    if (module === 'playground') {
      jsonData = {
        id: getUserId(),
      };
    }

    const config =
      module === 'imda'
        ? postConfigWithForm(formData)
        : postConfigWithJSON(jsonData);

    const result = await sendRequest(
      urlConfig[module].getAgeGroupResult,
      config
    );

    return result; // { id: .., age: string }
  };

  // Format
  // {
  //   id: Number;
  //   answers: {
  //     [0]: {
  //       answer: String;
  //       duration: Number;
  //       unfocus: Boolean;
  //     } ...etc
  //   }
  // }
  const sendQuestionnaire = async (answers) => {
    let jsonData = {};
    if (module === 'imda') {
      jsonData = {
        id: getUserId(),
        answers,
      };
    }

    if (module === 'playground') {
      jsonData = {
        id: getUserId(),
        ...answers,
      };
    }
    console.log('submititng quesstionnaire', jsonData);

    const result = await sendRequest(
      urlConfig[module].sendQuestionnaire,
      postConfigWithJSON(jsonData)
    );
    console.log('questionaire result', result);

    return result;
  };

  // Send feedback to database
  const sendFeedback = async (result, age_feedback, age_gt, comment) => {
    const jsonData = {
      id: getUserId(),
      result: result,
      age_feedback: age_feedback,
      age_gt: age_gt,
      comment: comment,
    };

    const res = await sendRequest(
      urlConfig[module].sendFeedback,
      postConfigWithJSON(jsonData)
    );
    return res;
  };

  return {
    getRandomPhrase,
    regenerateRandomPhrase,
    sendAudioRecording,
    getAgeGroupResult,
    sendQuestionnaire,
    sendFeedback,
  };
}
